import { Box, Grid, Typography, useTheme } from "@mui/material";
import Fade from "@mui/material/Fade";
import React, { useEffect } from "react";
import splash from "../../assets/images/engagement_bw.jpg";
import { Countdown } from "./countdown";

export const Home: React.FC = () => {
  const theme = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fade in={true} timeout={1000}>
      <Box>
        <Box
          sx={{
            backgroundImage: `url(${splash})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50%",
            backgroundSize: "cover",
            [theme.breakpoints.down("sm")]: { height: "50vh", marginTop: -10 },
            [theme.breakpoints.up("md")]: { height: "80vh" },
          }}
        >
          <Box
            sx={(theme) => ({
              height: "60vh",
              backgroundColor: "rgba(30,30,30,0.4)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "end",
              [theme.breakpoints.down("sm")]: { height: "50vh" },
              [theme.breakpoints.up("md")]: { height: "80vh" },
            })}
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography
                variant="h1"
                component="h1"
                sx={{
                  fontFamily: "Muthiara, Parisienne, cursive",
                  textAlign: "center",
                  color: "white",
                  display: "inline-block",
                  position: "relative",
                  whiteSpace: "nowrap",
                }}
              >
                Tanya & Aaron
              </Typography>
            </Box>
            <Box
              sx={{
                color: "white",
                width: "50%",
                maxWidth: 500,
                marginBottom: 10,
                [theme.breakpoints.down("sm")]: { marginBottom: 0 },
              }}
            >
              <Countdown />
            </Box>
          </Box>
        </Box>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={8}>
            <Box textAlign="center" padding={1}>
              <Typography variant="body1" gutterBottom>
                🌺 🌺 🌺 🌺 🌺
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Parisienne, cursive",
                  marginBottom: 3,
                }}
                variant="h2"
                component="h2"
              >
                We are getting married!
              </Typography>
              <Typography
                sx={(theme) => ({
                  fontSize: "1.5rem",
                  color: theme.palette.primary.main,
                  paddingBottom: "20px",
                })}
                variant="h2"
                component="h2"
              >
                Join us on the 26th of March, 2022.
              </Typography>
              {/* <Typography variant="h4" gutterBottom>
                Tanya and Aaron are getting married on March 26th, 2022 in
                beautiful Kauai, HI!
              </Typography> */}
              <Typography variant="body1">
                We are so excited to celebrate with all of you, our closest
                friends and family. Please take a moment to check out info about
                our story, the venue, events we have planned, and more!
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fade>
  );
};
