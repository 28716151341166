import { Box, Link as MuiLink, Typography } from "@mui/material";
import React, { useEffect } from "react";
import imageResort from "../../assets/images/kbr1.jpg";
import { Page } from "../../components/common/page";
import { SectionHeader } from "../../components/common/typography";

export const Venue: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page title="Venue">
      <SectionHeader>Kauai Beach Resort and Spa</SectionHeader>

      <Box
        component="img"
        sx={{ width: "100%", marginBottom: "1rem" }}
        src={imageResort}
        alt="Kauai Beach Resort & Spa"
      />
      <Typography sx={{ fontFamily: "Krub, sans-serif" }}>
        <Typography
          variant="body2"
          sx={(theme) => ({
            color: theme.palette.primary.main,
          })}
          gutterBottom
        >
          4331 Kauai Beach Dr, Lihue, HI 96766
        </Typography>
        <Box sx={{ marginBottom: 3 }}>
          <Typography variant="body1">Room Reservations</Typography>
          <Typography variant="body2">
            Rooms for all guests have already been booked and paid for (see{" "}
            <MuiLink href="/faq">FAQ</MuiLink> for more info).
          </Typography>
        </Box>
        <Typography variant="body1">Amenities</Typography>
        <Typography variant="body2">
          <ul>
            <li>
              4 pools including a sandy bottom oceanfront pool with pool bar
            </li>
            <li>2 waterfalls and a water slide</li>
            <li>2 hot tubs</li>
            <li>
              <MuiLink
                href="https://kauaibeachresortandspa.com/dining"
                target="_blank"
                rel="noopener noreferrer"
              >
                Onsite dining and beverages (Naupaka Terrace, Shutters Lounge,
                and Driftwood Bar)
              </MuiLink>
            </li>
          </ul>
        </Typography>
      </Typography>
    </Page>
  );
};
