import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import { Page } from "../../components/common/page";

export const PageNotFound: React.FC = () => {
  return (
    <Page title="">
      <Box sx={{ textAlign: "center", marginTop: 128 }}>
        <Typography variant="h1" fontSize="10rem">
          404
        </Typography>
        <Typography variant="h4" marginBottom={3}>
          Ooops! We couldn't find what you're looking for.
        </Typography>
        <Typography variant="h5">
          Would you like to return to the <a href="/">home page?</a>
        </Typography>
      </Box>
    </Page>
  );
};
