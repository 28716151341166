import { createTheme, responsiveFontSizes } from "@mui/material";

export let theme = createTheme({
  palette: {
    primary: {
      main: "#ff7f50",
    },
    secondary: {
      main: "#2E8B57",
    },
    success: {
      main: "#00ff00",
    },
    error: {
      main: "#FF0000",
    },
    background: {
      default: "#FBFBFB",
    },
    text: {
      primary: "#1A1A1A",
      secondary: "#8C8C8C",
    },
  },
});

const font = "Helvetica, sans-serif";

theme = createTheme(theme, {
  typography: {
    fontFamily: font,
    h1: {
      color: "#3D4351",
    },
    h2: {
      fontFamily: "Julius Sans One, sans-serif",
      color: "#3D4351",
    },
    h3: {
      fontSize: "2.3rem",
      color: "#3D4351",
    },
    body1: {
      fontFamily: "Oooh Baby, cursive",
      color: "dimgray",
      fontSize: "2.5rem",
    },
    body2: {
      fontFamily: "Krub, sans-serif",
      color: "dimgray",
      fontSize: "1.5rem",
    },
  },
});
theme = responsiveFontSizes(theme);
