import {
  Box,
  Link as MuiLink,
  Typography,
  TypographyProps,
} from "@mui/material";
import React from "react";

export const PageHeader: React.FC = ({ children }) => {
  return (
    <Box marginBottom={5}>
      <Typography
        sx={{
          fontFamily: "Muthiara, Parisienne, cursive",
          textTransform: "uppercase",
          letterSpacing: 10,
        }}
        variant="h1"
        component="h1"
      >
        {children}
      </Typography>
    </Box>
  );
};

export const SectionHeader: React.FC = ({ children }) => {
  return (
    <Box marginBottom={3}>
      <Typography
        sx={{
          textTransform: "uppercase",
          letterSpacing: 2,
        }}
        variant="h2"
        component="h2"
      >
        {children}
      </Typography>
    </Box>
  );
};

export const BodyFancy: React.FC<TypographyProps> = ({
  children,
  ...restProps
}) => {
  return (
    <Typography variant="body1" {...restProps}>
      {children}
    </Typography>
  );
};

export const BodySans: React.FC<TypographyProps> = ({
  children,
  ...restProps
}) => {
  return (
    <Typography variant="body2" {...restProps}>
      {children}
    </Typography>
  );
};

export const SiteLink: React.FC<{
  external?: boolean;
  href: string;
  text: string;
}> = ({ external, href, text }) => {
  const externalProps = external
    ? { target: "_blank", rel: "noopener noreferrer" }
    : {};
  return (
    <MuiLink href={href} {...externalProps}>
      {text}
    </MuiLink>
  );
};

export const Address: React.FC<{
  text: string;
}> = ({ text }) => {
  return (
    <Typography
      variant="body2"
      sx={(theme) => ({ color: theme.palette.primary.main })}
    >
      {text}
    </Typography>
  );
};
