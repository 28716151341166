import Menu from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const tabs = ["Home", "About", "Venue", "Events", "FAQ"];

export const Header: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { pathname } = useLocation();

  return (
    <div>
      <AppBar
        sx={() => ({
          flex: 1,
          backgroundColor: "rgba(20,20,20,0.95)",
          sm: {
            height: 64,
            paddingTop: 4,
          },
          color: "white",
        })}
      >
        <Toolbar>
          <Box
            component="span"
            style={{ width: "100%" }}
            sx={{ display: { xs: "none", sm: "flex" } }}
          >
            <NavButton pathname="/home" isSelected={pathname === "/home"}>
              Home
            </NavButton>
            <NavButton pathname="/about" isSelected={pathname === "/about"}>
              About
            </NavButton>
            <NavButton pathname="/venue" isSelected={pathname === "/venue"}>
              Venue
            </NavButton>
            <NavButton pathname="/events" isSelected={pathname === "/events"}>
              Events
            </NavButton>
            <NavButton pathname="/faq" isSelected={pathname === "/faq"}>
              FAQ
            </NavButton>
          </Box>
          <Box
            component="span"
            sx={{
              display: { xs: "flex", sm: "none" },
              width: "100%",
            }}
          >
            <IconButton
              edge="start"
              aria-label="menu"
              color="inherit"
              onClick={() => setDrawerOpen(true)}
            >
              <Menu />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="persistent"
        anchor="left"
        open={drawerOpen}
        sx={{
          display: { sm: "none" },
        }}
      >
        <Box>
          <Box
            sx={{
              paddingLeft: 1,
              paddingTop: 0.25,
              height: 55,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <IconButton onClick={() => setDrawerOpen(false)}>
              <Menu />
            </IconButton>
          </Box>
          <Divider />
          {drawerOpen && (
            <ClickAwayListener onClickAway={() => setDrawerOpen(false)}>
              <List
                sx={{ width: "50vw", display: "flex", flexDirection: "column" }}
              >
                {tabs.map((tab) => (
                  <Button
                    key={tab}
                    component={Link}
                    to={{ pathname: "/" + tab }}
                    sx={{ textDecoration: "none", color: "inherit" }}
                  >
                    <ListItem
                      button
                      key={tab}
                      onClick={() => setDrawerOpen(false)}
                    >
                      <ListItemText primary={tab} />
                    </ListItem>
                  </Button>
                ))}
              </List>
            </ClickAwayListener>
          )}
        </Box>
      </Drawer>
    </div>
  );
};

const navItemStyles = {
  color: "white",
  minWidth: 80,
};
const navItemSelectedStyles = {
  ...navItemStyles,
  borderRadius: "50vw",
  borderColor: "white",
};

const NavButton: React.FC<{ pathname: string; isSelected: boolean }> = ({
  pathname,
  children,
  isSelected,
}) => (
  <Link to={{ pathname }}>
    <Button
      sx={isSelected ? navItemSelectedStyles : navItemStyles}
      variant={isSelected ? "outlined" : "text"}
    >
      {children}
    </Button>
  </Link>
);
