import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@mui/material";
import React from "react";
import imageCamping from "../../assets/images/about-camping.jpg";
import imageHorseback from "../../assets/images/about-horseback.jpg";
import imageQuarantine from "../../assets/images/about-irvine.jpg";
import imageKayak from "../../assets/images/about-kayak.jpg";
import imageMeet from "../../assets/images/about-meet.jpg";
import imageProposal from "../../assets/images/about-proposal.jpg";
import imageLaughing from "../../assets/images/laughing1.jpg";
import { Page } from "../../components/common/page";
import { SectionHeader } from "../../components/common/typography";

interface AboutItem {
  date: string;
  title: string;
  imgSrc: string;
  detail: string;
}

const items: AboutItem[] = [
  {
    date: "October 2019",
    title: "Tanya and Aaron Connect",
    imgSrc: imageMeet,
    detail: `After unmatching Aaron the first time they connected, the second time around Tanya thought
  Aaron was cute enough to go out on date.`,
  },
  {
    date: "November 2019",
    title: "First Date",
    imgSrc: imageKayak,
    detail: `Tanya, Aaron, and Captain went kayaking in Newport. Tanya told Aaron
    about how she likes to watercolor and travel, and Aaron misremembered
    how old he was.`,
  },
  {
    date: "February 2020",
    title: "First Valentine's Day",
    imgSrc: imageHorseback,
    detail: `A trip up the coast that ended with a horseback ride in Montecito earned
    Aaron some points.`,
  },
  {
    date: "Mid-2020",
    title: "Quarantine Partners",
    imgSrc: imageQuarantine,
    detail: `Quarantining together, Tanya and Aaron made the best of a non-traditional
    first year dating. Instead of movies and nights out on the town, they started gardening,
    making bread (Tanya making, Aaron eating), and playing guitar together.`,
  },
  {
    date: "Mid-2020",
    title: "Outdoor Activities",
    imgSrc: imageCamping,
    detail: `Camping and hiking were still possible, so the two made time for trips to
    Oregon, Sequoia, Idyllwild.`,
  },
  {
    date: "October 2021",
    title: "Photo Shoot in Santorini",
    imgSrc: imageLaughing,
    detail: `We weren't very good models, but we had a lot of laughs!`,
  },
  {
    date: "October 2021",
    title: "Proposal",
    imgSrc: imageProposal,
    detail: `With the help of a local photographer, Aaron surprised Tanya with a sunset
    proposal on the cliffs of Santorini overlooking the ocean. She said yes!`,
  },
];

export const About: React.FC = () => {
  return (
    <Page title="About">
      <SectionHeader>Our Story</SectionHeader>
      {items.map((item, i) => (
        <TimelineCard
          key={item.title}
          imgSrc={item.imgSrc}
          date={item.date}
          title={item.title}
          dir={i % 2 ? "Right" : "Left"}
        >
          <Typography gutterBottom variant="body2">
            {item.detail}
          </Typography>
        </TimelineCard>
      ))}
    </Page>
  );
};

const TimelineCard: React.FC<{
  imgSrc: string;
  date: string;
  title: string;
  dir: "Left" | "Right";
}> = ({ imgSrc, date, title, children, dir }) => {
  return (
    <Box
      sx={(theme) => ({
        position: "relative",
        paddingTop: "20px",
        paddingBottom: "20px",
        marginBottom: "30px",
        [theme.breakpoints.down("sm")]: {
          marginTop: 12,
          paddingBottom: 0,
          marginBottom: 0,
        },
      })}
    >
      <Box
        sx={(theme) => ({
          position: "absolute",
          top: 0,
          [`${dir.toLocaleLowerCase()}`]: 0,
          width: 300,
          height: 300,
          backgroundImage: `url(${imgSrc})`,
          backgroundSize: "cover",
          display: "block",
          borderRadius: "50%",
          border: "4px solid coral",
          [theme.breakpoints.down("sm")]: {
            [`${dir.toLocaleLowerCase()}`]: "auto",
            right: 0,
            top: -85,
            border: "2px solid coral",
            // borderRadius: 0,
            height: 150,
            width: 150,
            // marginTop: -5,
            // position: "initial",
          },
        })}
      />
      <Box display="flex" alignItems="center" flexGrow="1">
        <Card
          sx={(theme) => ({
            [`padding${dir}`]: dir ? 18 : undefined,
            minHeight: 260,
            width: "100%",
            [`margin${dir}`]: 20,
            [theme.breakpoints.down("sm")]: {
              [`margin${dir}`]: 0,
              [`padding${dir}`]: 1,
              minHeight: 200,
            },
          })}
        >
          <CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="body2" color="coral">
                {date}
              </Typography>
              <Typography gutterBottom>{title}</Typography>
              {children}
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>
    </Box>
  );
};
