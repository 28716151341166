import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Link as MuiLink } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import React, { useCallback } from "react";
import { Page } from "../../components/common/page";

export const FAQ: React.FC = () => {
  const [expanded, setExpanded] = React.useState<string | null>(null);

  const handleChange = useCallback(
    (panel: string) => () => {
      setExpanded((expanded) => (expanded === panel ? null : panel));
    },
    []
  );

  return (
    <Page title="FAQ">
      <Box sx={{ textAlign: "left" }}>
        {/* ****************************************** */}
        <FAQSectionTitle>Travel & Accomodations</FAQSectionTitle>
        <FAQItem
          id="covidprotocol"
          expandedID={expanded}
          changeCallback={handleChange}
          question="What are the travel requirements for visitors to Hawaii?"
        >
          <FAQAnswer>
            <Box
              component="span"
              sx={{ fontWeight: 900, textDecoration: "underline" }}
            >
              For Vaccinated Travelers:{" "}
            </Box>
            The vaccination record document must be uploaded to Safe Travels and
            printed out prior to departure and the traveler must have a hard
            copy in hand when arriving in Hawaiʻi. The State of Hawai‘i will
            also accept vaccine records that are digitally validated by Digital
            Health Pass Partners (AZOVA, CLEAR and CommonPass).
            <br />
            <br />
            <Box
              component="span"
              sx={{ fontWeight: 900, textDecoration: "underline" }}
            >
              For Unvaccinated Travelers:{" "}
            </Box>
            Must have negative results from a test taken from a Trusted Testing
            and Travel Partner no more than 72 hours BEFORE beginning the final
            leg of their trip. The negative test result must be uploaded onto
            Safe Travels or printed out prior to departure and hard copy in hand
            when arriving in Hawai‘i.
            <br />
            <br />
            <Box
              component="span"
              sx={{ fontWeight: 900, textDecoration: "underline" }}
            >
              For ALL Travelers:{" "}
            </Box>
            ​​Travelers must create an account in Safe Travels Hawai‘i, and
            follow the instructions. Register Here:{" "}
            <MuiLink
              href="https://travel.hawaii.gov/#/registration"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://travel.hawaii.gov/#/registration
            </MuiLink>
          </FAQAnswer>
        </FAQItem>
        <FAQItem
          id="hotelcharges"
          expandedID={expanded}
          changeCallback={handleChange}
          question="How will I be charged for the room?"
        >
          <FAQAnswer>
            Tanya and Aaron will be taking care of the room cost, taxes, and
            resort fees from 3/25-/3/29. Parking fees and any additional room
            services will not be covered. The hotel will request a credit card
            from you for these other incidentals.
          </FAQAnswer>
        </FAQItem>

        {/* ****************************************** */}
        <FAQSectionTitle>Welcome Dinner</FAQSectionTitle>

        <FAQItem
          id="welcomedinnerattire"
          expandedID={expanded}
          changeCallback={handleChange}
          question="What is the attire for the 3/25 welcome dinner?"
        >
          <FAQAnswer>Smart casual.</FAQAnswer>
        </FAQItem>

        {/* ****************************************** */}
        <FAQSectionTitle>Ceremony & Reception</FAQSectionTitle>

        <FAQItem
          id="ceremonyguestphotos"
          expandedID={expanded}
          changeCallback={handleChange}
          question="Will guests have a chance to take photos with the bride and groom?"
        >
          <FAQAnswer>
            Immediately after the wedding ceremony we'll have about 30 minutes
            to take photos with guests. We want to get pictures with all of you!
          </FAQAnswer>
        </FAQItem>

        <FAQItem
          id="ceremonyattire"
          expandedID={expanded}
          changeCallback={handleChange}
          question="What is the attire for the 3/26 ceremony and reception?"
        >
          <FAQAnswer>Cocktail attire.</FAQAnswer>
        </FAQItem>

        <FAQItem
          id="receptiontime"
          expandedID={expanded}
          changeCallback={handleChange}
          question="When will the reception take place?"
        >
          <FAQAnswer>
            The reception will take place directly following the ceremony and is
            located in the same location. There will be appetizers and drinks
            served. Guests who are finished taking photos may head over to the
            reception area for cocktail hour. Dinner will be served at 6:30 pm
          </FAQAnswer>
        </FAQItem>

        <FAQItem
          id="receptionbooze"
          expandedID={expanded}
          changeCallback={handleChange}
          question="Will there be alcohol at the reception dinner?"
        >
          <FAQAnswer>
            We will have a hosted bar available during the reception. There will
            be cocktails made to order as well as wine and beer options. We will
            not be serving straight shots.
          </FAQAnswer>
        </FAQItem>

        {/* ****************************************** */}
        <FAQSectionTitle>Activities</FAQSectionTitle>
        <FAQItem
          id="tubing"
          expandedID={expanded}
          changeCallback={handleChange}
          question="What should I wear/bring for tubing adventure?"
        >
          <FAQAnswer>
            <ul>
              <li>
                Water-friendly shoes. <strong>NO FLIP FLOPS </strong> (rental
                shoes available)
              </li>
              <li>Swimsuits or Clothing that can get wet</li>
              <li>Towels & Change of dry clothing (optional)</li>
              <li>Reef-Safe Sunscreen (optional)</li>
              <li>Bug Repellent (optional)</li>
              <li>Bag to keep your wet clothes and belongings in (optional)</li>
            </ul>
          </FAQAnswer>
        </FAQItem>

        {/* ****************************************** */}
        <FAQSectionTitle>Other</FAQSectionTitle>
        <FAQItem
          id="registry"
          expandedID={expanded}
          changeCallback={handleChange}
          question="Is there a wedding registry?"
        >
          <FAQAnswer>
            At this time, we do not have a wedding registry set up.
          </FAQAnswer>
        </FAQItem>
      </Box>
    </Page>
  );
};

const FAQItem: React.FC<{
  id: string;
  question: string;
  expandedID: string | null;
  changeCallback: (panel: string) => () => void;
}> = ({ children, id, question, expandedID, changeCallback }) => {
  return (
    <Box marginBottom={2}>
      <Accordion expanded={expandedID === id} onChange={changeCallback(id)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <FAQQuestion>{question}</FAQQuestion>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </Box>
  );
};

const FAQSectionTitle: React.FC = ({ children }) => (
  <Typography
    sx={{
      color: "#3D4351",
      textTransform: "uppercase",
      letterSpacing: 3,

      padding: 2,
    }}
    variant="h2"
    component="h2"
  >
    {children}
  </Typography>
);

const FAQQuestion: React.FC = ({ children }) => (
  <Typography
    sx={(theme) => ({
      fontFamily: "sans-serif",
      color: theme.palette.primary.main,
      textTransform: "uppercase",
      paddingX: 2,
      paddingY: 1,
    })}
  >
    {children}
  </Typography>
);

const FAQAnswer: React.FC = ({ children }) => (
  <Typography
    sx={(theme) => ({
      fontFamily: "Krub, sans-serif",
      paddingX: 2,
      paddingBottom: 1,
    })}
  >
    {children}
  </Typography>
);
