import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

const BIG_DAY = new Date(2022, 2, 26, 16, 30);

export const Countdown: React.FC = () => {
  const [[d, h, m, s], setTime] = useState<[number, number, number, number]>(
    getCountDown()
  );

  useEffect(() => {
    const interval = setInterval(() => setTime(getCountDown()), 1000 * 60);
    return () => {
      clearInterval(interval);
    };
  }, [s]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container sx={{ textAlign: "center" }}>
        <NumberText n={d} />
        <NumberText n={h} />
        <NumberText n={m} />
        {/* <NumberText n={s}/> */}
        <LabelText l="Days" />
        <LabelText l="Hours" />
        <LabelText l="Min" />
        {/* <NumberText l="Sec" /> */}
      </Grid>
    </Box>
  );
};

const NumberText: React.FC<{ n: number }> = ({ n }) => (
  <Grid item xs={4} display="flex" justifyContent="center">
    <Typography
      sx={(theme) => ({
        fontSize: "2rem",
        fontWeight: 600,
        color: "white",
        paddingBottom: 0,
        [theme.breakpoints.down("sm")]: { fontSize: "1.2rem" },
      })}
    >
      {n}
    </Typography>
  </Grid>
);

const LabelText: React.FC<{ l: string }> = ({ l }) => (
  <Grid item xs={4} display="flex" justifyContent="center">
    <Typography
      sx={(theme) => ({
        fontSize: "1.5rem",
        textTransform: "uppercase",
        color: "white",
        [theme.breakpoints.down("sm")]: { fontSize: "1rem" },
      })}
    >
      {l}
    </Typography>
  </Grid>
);

function getCountDown(): [number, number, number, number] {
  let delta = Math.abs(Math.max(BIG_DAY.getTime() - Date.now(), 0)) / 1000;

  // calculate (and subtract) whole days
  const d = Math.floor(delta / 86400);
  delta -= d * 86400;

  // calculate (and subtract) whole hours
  const h = Math.floor(delta / 3600) % 24;
  delta -= h * 3600;

  // calculate (and subtract) whole minutes
  const m = Math.floor(delta / 60) % 60;
  delta -= m * 60;

  // what's left is seconds
  const s = Math.floor(delta % 60);

  return [d, h, m, s];
}
