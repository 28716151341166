import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  Divider,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import imageCeremony from "../../assets/images/ceremony1.jpg";
import imageReception from "../../assets/images/event-reception.jpg";
import imageJO2 from "../../assets/images/jo2.jpg";
import imageSnorkeling from "../../assets/images/snorkeling.jpg";
import imageTubing from "../../assets/images/tubing.jpg";
import imageCanyon from "../../assets/images/waimeacanyon.jpg";
import { Page } from "../../components/common/page";
import {
  Address,
  BodyFancy,
  BodySans,
  SiteLink,
} from "../../components/common/typography";

interface EventProps {
  title: string;
  date: Date;
  imgSrc: string;
  shortDesc: string;
  address: string;
  content?: React.ReactNode;
}

const events: EventProps[] = [
  {
    title: "Welcome Dinner at JO2 (optional)",
    date: new Date("2022-03-25T17:00:00.000-10:00"),
    imgSrc: imageJO2,
    shortDesc: "Join us for dinner at the JO2 restaurant",
    address: "4-971 Kuhio Hwy, Kapaʻa, HI 96746",
    content: (
      <Typography variant="body2">
        Please let us know if you <em>don't</em> plan on attending the welcome
        dinner, otherwise, we've got a seat for you!
      </Typography>
    ),
  },
  {
    title: "Ceremony",
    date: new Date("2022-03-26T16:30:00.000-10:00"),
    imgSrc: imageCeremony,
    shortDesc: "Ceremony on the beach lawn at Kauai Beach Resort",
    address: "4331 Kauai Beach Dr, Lihue, HI 96766",
    content: (
      <Typography variant="body2">
        Ceremony will start at 4:30PM. The photographer will be there starting
        at 4:00PM so please come down and have him take some pictures of your
        beautiful selves!
      </Typography>
    ),
  },
  {
    title: "Reception",
    date: new Date("2022-03-26T18:30:00.000-10:00"),
    imgSrc: imageReception,
    shortDesc: "Reception dinner",
    address: "4331 Kauai Beach Dr, Lihue, HI 96766",
    content: (
      <Box sx={{ textAlign: "center" }}>
        <BodyFancy gutterBottom>Buffet-Style Dinner</BodyFancy>
        <Box sx={{ paddingY: 1 }}>
          <BodySans>
            • Local Baby Lettuce, Roasted Beets, Grilled Sourdough, Laura Chenel
            Goat Cheese
          </BodySans>
          <BodySans>• Seasonal Tropical Fruit</BodySans>
          <BodySans>• Somen Salad</BodySans>
        </Box>
        <Divider />
        <Box sx={{ paddingY: 1 }}>
          <BodySans>• Ahi Poke, Limu, Inamona, Scallion, Pa'akai </BodySans>
          <BodySans>• Lomi Lomi Salmon </BodySans>
          <BodySans>Hanalei Poi </BodySans>
        </Box>
        <Divider />
        <Box sx={{ paddingY: 1 }}>
          <BodySans>• Imu Style Kalua Pig</BodySans>
          <BodySans>• Huli Huli Chicken </BodySans>
          <BodySans>• Kiawe Grilled New York Steaks</BodySans>
          <BodySans>Seared Local Catch, Citrus Butter Sauce</BodySans>
        </Box>
        <Divider />
        <Box sx={{ paddingY: 1 }}>
          <BodySans>• Steamed Rice</BodySans>
          <BodySans>• Pancit Noodles</BodySans>
          <BodySans>• Sauteed Vegetables, Ginger, Sesame</BodySans>
        </Box>
        <Divider />
        <Box sx={{ paddingY: 1 }}>
          <BodySans>• House Baked Rolls & Butter</BodySans>
        </Box>
        <Divider />
        <Box sx={{ paddingY: 1 }}>
          <BodySans>• Dessert</BodySans>
        </Box>

        <BodyFancy gutterBottom sx={{ marginTop: 3 }}>
          Drinks
        </BodyFancy>
        <Typography variant="body2">
          House drinks paid for; crazy stuff is on you. (see{" "}
          <SiteLink href="/faq" text="FAQ" /> for more info).
        </Typography>
      </Box>
    ),
  },
  {
    title: "Tubing in Lihue (optional)",
    date: new Date("2022-03-27T10:45:00.000-10:00"),
    imgSrc: imageTubing,
    shortDesc: "3-hour tubing cruise down the river",
    address: "3-4131 Kuhio Hwy, Lihue, HI 96766",
    content: (
      <Box>
        <Typography variant="body2">
          <ul>
            <li>
              Group A (10 riders total): depart from resort at 10:45 am, group
              must arrive at tour location by 11 am
            </li>
            <li>
              Group B (13 riders total): depart from resort at 11:10 am, group
              must arrive at tour location by 11:30 am
            </li>
          </ul>
          What to bring:
          <ul>
            <li>
              Water-friendly shoes. <strong>NO FLIP FLOPS </strong> (rental
              shoes available)
            </li>
            <li>Swimsuits or Clothing that can get wet</li>
            <li>Towels & Change of dry clothing (optional)</li>
            <li>Reef-Safe Sunscreen (optional)</li>
            <li>Bug Repellent (optional)</li>
            <li>Bag to keep your wet clothes and belongings in (optional)</li>
          </ul>
          Provided:
          <ul>
            <li>Gloves</li>
            <li>Sandwiches and water</li>
            <li>Headlamps</li>
            <li>Tubes</li>
          </ul>
          Restrictions:
          <ul>
            <li>Weight limit - 300lbs</li>
            <li>Unless weather prohibits, tours go out rain or shine</li>
          </ul>
          For more info:
          <SiteLink
            href="https://kauaibackcountry.com/tubing/"
            text="kauaibackcountry.com"
            external
          />
        </Typography>
      </Box>
    ),
  },
  {
    title: "Waimea Canyon Drive (optional)",
    date: new Date("2022-03-28T09:00:00.000-10:00"),
    imgSrc: imageCanyon,
    shortDesc:
      "Known as the “Grand Canyon” of the Kauai, this scenic and colorful canyon is a must-see in Kauai.",
    address: "",
    content: (
      <>
        <Typography variant="body2">
          It is approximately 1 hour and 10 minutes from the resort. We will
          plan to caravan together, stopping at different look out points.
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: 3 }}>
          Plan to meet in hotel lobby and depart from the resort at 9 AM
        </Typography>
        <Typography variant="body2">
          <Typography variant="body2">
            What to bring/wear:
            <ul>
              <li>Closed toe tennis shoes</li>
              <li>Hat</li>
              <li>Sunglasses</li>
              <li>Water bottle</li>
              <li>Sunscreen</li>
              <li>Light jacket (in case of wind or rain)</li>
            </ul>
            Lunch:{" "}
            <BodySans sx={{ paddingLeft: 2 }}>
              After driving along and viewing the canyon, we will stop for lunch
              at The Shrimp Station:{" "}
              <Address text="9652 Kaumualii Hwy, Waimea, Kauai, HI 96796" />
            </BodySans>
          </Typography>
        </Typography>
      </>
    ),
  },
  {
    title: "Snorkeling (optional)",
    date: new Date("2022-03-28T16:00:00.000-10:00"),
    imgSrc: imageSnorkeling,
    shortDesc: "Ceremony on the beach lawn at Kauai Beach Resort",
    address: "",
    content: (
      <Typography variant="body2">
        After exploring the Waimea Canyon, we will stop on our way back at
        Lawai’i Beach for some snorkeling. This area is known for sea turtle
        sightings! If you are not interested in snorkeling, you can head back to
        the resort or head over to the town of Poipu (5 min away from Lawai’i
        Beach) for some souvenir shopping or Hawaiian shaved ice!
      </Typography>
    ),
  },
];

export const Events: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page title="Events">
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {events.map(({ content, ...restProps }) => (
          <EventCard key={restProps.title} {...restProps}>
            {content}
          </EventCard>
        ))}
      </Box>
    </Page>
  );
};

const EventCard: React.FC<EventProps> = ({
  title,
  date,
  shortDesc,
  imgSrc,
  children,
  address,
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const formattedDate = date.toLocaleString("en-US", {
    timeZone: "Pacific/Honolulu",
    weekday: "long",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: undefined,
    timeZoneName: "short",
  });

  return (
    <Card sx={{ margin: 2, width: 500, height: "100%" }}>
      <CardHeader
        title={title}
        subheader={formattedDate}
        titleTypographyProps={{
          sx: (theme) => ({
            ...theme.typography.h4,
            fontFamily: "Julius Sans One, sans-serif",
            color: "#3D4351",
            fontSize: "1.5rem",
            textTransform: "uppercase",
          }),
        }}
        subheaderTypographyProps={{
          sx: (theme) => ({
            ...theme.typography.h5,
            fontFamily: "Krub, sans-serif",
            color: theme.palette.primary.main,
          }),
        }}
      />
      <CardMedia component="img" image={imgSrc} sx={{ maxHeight: 300 }} />
      <CardContent>
        <Typography variant="body1">{shortDesc}</Typography>
        <Address text={address} />
      </CardContent>
      {children && (
        <>
          <CardActions disableSpacing>
            {/* <Button startIcon={<EventIcon />} disabled>
              Add to calendar
            </Button> */}
            {!expanded && (
              <Button
                variant="outlined"
                endIcon={<ExpandMoreIcon />}
                onClick={handleExpandClick}
                sx={{ marginLeft: "auto" }}
              >
                More info
              </Button>
            )}
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent
              sx={(theme) => ({
                textTransform: "none",
                color: "dimgray",
              })}
            >
              {children}
            </CardContent>
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                justifyContent: "flex-end",
                margin: 2,
              }}
            >
              <Button
                variant="outlined"
                endIcon={<ExpandLessIcon />}
                onClick={handleExpandClick}
                sx={{ marginLeft: "auto" }}
              >
                Collapse
              </Button>
            </Box>
          </Collapse>
        </>
      )}
    </Card>
  );
};
