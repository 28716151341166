import { Container, Fade } from "@mui/material";
import React from "react";
import { PageHeader } from "./typography";

export const Page: React.FC<{ title: string }> = ({ children, title }) => {
  return (
    <Fade in={true} {...{ timeout: 1000 }}>
      <Container
        sx={(theme) => ({
          marginTop: "12vh",
          padding: 3,
          marginX: "auto",
        })}
      >
        <PageHeader>{title}</PageHeader>
        {children}
      </Container>
    </Fade>
  );
};
